<template>
  <section :style="{cursor : disabled ? 'auto' : 'pointer'}" :class="{checked: this.value}" @click="handleClick" class="checkbox-container">
    <img src="@/assets/survey/checkmark_enabled.svg" alt="checkmark_enabled" />
    <img src="@/assets/survey/checkmark_disabled.svg" alt="checkmark_disabled" />
  </section>
</template>

<script>
export default {
  props: {
    value: {
      type:  Boolean,
      required: true,
      default: false
    },
    disabled:{
      type:  Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      currValue: this.value
    };
  },
  methods: {
    handleClick() {
      // this.currValue = !this.currValue;
      this.$emit("input", !this.value);
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/vars";
@import "@/styles/mixins";

.checkbox-container {
  width: 1.875vw;
  height: 1.875vw;
  border: 1px solid #ededed;
  background-color: $white;
  border-radius: 4px;
  margin: 0 10px;
  @include flex-center;
  img {
    width: 60%;
  }
  img:first-child {
    display: none;
  }
  img:nth-child(2) {
    display: block;
  }
  &.checked {
    background: $blue;
    img:first-child {
      display: block;
    }
    img:nth-child(2) {
      display: none;
    }
  }
}
</style>