<template>
  <div class="loading-container" :style="{ height }">
    <img src="@/assets/loading.gif" alt/>
  </div>
</template>

<script>
export default {
  props: {
    height: { type: String, default: '20vh' }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";

.loading-container {
  @include flex-center;
}
</style>