import ajax from '@/modules/common/services/httpService';
import { sessionLoad, sessionStore } from '@/modules/common/services/storageService'
import { ddmmyyhhmm } from '@/filters'
import swalService from "@/modules/common/services/swalService";


const BASE_URL = 'survey';
const LANGUAGES_URL = 'languages'

export default { getCompanyTemplates, getSurveyList, removeSurvey, getSurveyById, saveSurvey, openSurveyPreview, duplicateSurvey, getLanguages, saveSurveyResponse, saveSurveyResponseContent };

/**@param {{site_id?: Number | String}} query*/
async function getSurveyList(query) {
    const surveys = await ajax.get(BASE_URL, null, query);
    return surveys;
}

/**@param { Number | String} company_id*/
async function getCompanyTemplates(company_id) {
    const surveys = await ajax.get(`${BASE_URL}/template/${company_id}`);
    return surveys;
}

/**@param {Number | String} id */
async function getSurveyById(id) {
    try {
        const res = await ajax.get(`${BASE_URL}/${id}`);
        return res;
    } catch (err) {
        swalService.errorMsg();
    }
}

/**@param {Number | String} id */
async function duplicateSurvey(id) {
    try {
        const res = await ajax.get(`${BASE_URL}/duplicate/${id}`);
        return res;
    } catch (err) {
        swalService.errorMsg();
    }
}

/**@param {Number | String} id */
async function removeSurvey(id) {
    const res = await ajax.delete(`${BASE_URL}/${id}`);
    return res;
}

/**@param {{
 * id: Number | String;
 * name:String;
 * created:String;
 * created_by:String;
 * updated:String;
 * updated_by:String;
 * pages:Array;
 * site_id?: Number | String;
 * }} survey */
async function saveSurvey(survey, siteId) {
    if (!survey.id) {
        survey.site_id = siteId;
        const addedSurvey = await ajax.post(BASE_URL, survey);
        addedSurvey.pages = JSON.parse(addedSurvey.pages)
        addedSurvey.api = JSON.parse(addedSurvey.api)
        addedSurvey.email_settings = JSON.parse(addedSurvey.email_settings)
        return addedSurvey;
    } else {
        const res = await ajax.put(BASE_URL, survey);
        return res;
    }

}

/**@param {Number | String} surveyId */
function openSurveyPreview(surveyId) {
    sessionStore('surveyId', surveyId);
    const url = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8080';
    window.open(`${url}/survey-app`);
}

export class SurveyToShow {
    /**
     * @param {{
     * id: Number | string;
     * name: string;
     * deviceNumber: Number;
     * pageNumber: Number;
     * created: string;
     * updated_by: string;
     * updated: string;
     * is_template: boolean;
     * }} survey 
     * @param {'admin'} [userType]
     */
    constructor({ id, name, deviceNumber, pageNumber, created, updated_by, updated, is_template }, userType) {
        this.id = id;
        this.survey_name = name;
        this.number_of_assigned_devices = deviceNumber;
        this.number_of_pages = pageNumber;
        this.date_created = ddmmyyhhmm(created);
        this.updated_by = updated_by;
        this.date_updated = ddmmyyhhmm(updated);
        if (userType === 'admin') this.is_template = { value: is_template, type: 'checkbox', action: 'survey-template' };
    }
}

async function getLanguages() {
	const languagesInfo = await ajax.get(LANGUAGES_URL)
	return languagesInfo
}

async function saveSurveyResponse(responseInfo) {
    const response = await ajax.post(`${BASE_URL}/response`, responseInfo)
    return response
}

async function saveSurveyResponseContent(content) {
    const res = await ajax.post(`${BASE_URL}/response-content`, content)
    return res
}