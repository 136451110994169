import ajax from '../../common/services/httpService';
import util from '../../common/services/utilService';
import utilService from '../../common/services/utilService';

const BASE_URL = 'room';
const DEVICE_OK_TIME = 1;
const PAD_OK_TIME = 1;
const DEVICE_WARNING_TIME = 3

/**@typedef {{
 * id?: String | Number;
 * display_name: String;
 * gender?: 'men / women' | 'men' | 'women' | 'handycap';
 * building?: String;
 * floor?: String;
 * zone?: String;
 * site_id?: Number | String;
 * survey_id?: String;
 * }} Room*/

/**@typedef {{
 * name: String;
 * roomNumber:Number;
 * siteId: Number | String;
 * avgCellNumber: Number;
 * floorNumber: Number;
 * disabledToiletNumber: Number;
 * soapDispensers: Number;
 * }} Building*/

/**@returns {Promise<Room[]>} */
async function getRooms(query) {
    const rooms = await ajax.get(BASE_URL, null, { siteId: query.site });
    return rooms
}

/**@param {Building} building */
function generateRoomMap(building) {
    let rooms = [];
    if (building.floorNumber > 100) throw new Error('too many floors');
    if (building.roomNumber > 200) throw new Error('too many rooms');
    for (let i = 0; i < building.floorNumber; i++) {
        for (let j = 1; j <= building.roomNumber; j++) {
            rooms.push({
                display_name: `floor-${i}-room-${j}`,
                building: building.name,
                floor: i,
                site_id: building.siteId
            })
        }
    }
    return rooms;
}

/**@param {Room} room */
async function submitRoom(room) {
    if (!room.id) {
        const res = await ajax.post(BASE_URL, room);
        return res;
    } else {
        const res = await ajax.put(BASE_URL, room);
        return res;
    }
}

/**@param {Room[]} rooms */
async function submitRoomList(rooms) {
    const res = await ajax.post(BASE_URL, rooms);
    return res;
}

/**@param {Number | String} id */
async function getRoomById(id) {
    /**@type {Room} */
    const res = await ajax.get(`${BASE_URL}/${id}`);
    return res;
}

/**@param {Number | String} id*/
async function removeRoom(id) {
    const res = await ajax.delete(`${BASE_URL}/${id}`);
    return res;
}

/**@param {Array<String | Number>} ids */
async function removeRoomMany(ids) {
    const res = await ajax.delete(BASE_URL, ids);
    return res;
}

function getProcessedRooms(rooms, startOfTooltip = 'last') {
    const roomsProcessed = rooms.map(room => {
        room.sensors = room.sensors.map(sensor => {
            let lastSignalDate = new Date(sensor.lastSignal);
            const isPad = sensor.type === 'pad9' || sensor.type === 'pad9+'
            //for some reason data is getting later then current time NEED TO BE FIXED IN PROCESS
            if (new Date().getTime() < lastSignalDate.getTime()) lastSignalDate = new Date();
            let lastSignalString = util.dateToDD_MM_YYYY(lastSignalDate);
            lastSignalString += `/${lastSignalDate.toTimeString().slice(0, 5)}`
            const processedSensor = {
                installSpot: sensor.desc,
                type: sensor.type,
                deviceCode: sensor.deviceCode,
                status: {
                    icon: _getStatusIcon(lastSignalDate, { sensor, isPad }),
                    tooltip: { boldText: `${startOfTooltip} `, text: lastSignalString }
                },
                id: sensor.deviceCode,
            };
            delete sensor.desc;
            return processedSensor;
        });
        room.nestedTable = room.sensors;
        room.device = room.sensors.length;
        delete room.sensors;
        return room;
    });
    return roomsProcessed
}

/**@param {Date} date */
/**@param {String} state */
/**@param {String} type */
function _getStatusIcon(date, { state, isPad }) {
    const hoursSinceLastSignal = utilService.getHoursPassed(date)
    if (isPad) return _getStatusIconByState({ state, hoursPassed: hoursSinceLastSignal })
    else return _getStatusIconByLastSignalDate({ hoursPassed: hoursSinceLastSignal})
}

/**@param {String} state */
/**@param {Number} hoursPassed */
function _getStatusIconByState({state, hoursPassed}) {
    // if (hoursPassed < PAD_OK_TIME || state === 'online') return 'OK';
    // if (!state || state === 'offline')  return 'offline';
    if (hoursPassed <= PAD_OK_TIME) return 'OK';
    else if (hoursPassed > PAD_OK_TIME) return 'offline';
}

/**@param {Number} hoursPassed */
function _getStatusIconByLastSignalDate({ hoursPassed }) {
    if (hoursPassed < DEVICE_OK_TIME) return 'OK';
    else if (hoursPassed < DEVICE_WARNING_TIME) return 'warning_orange';
    else return 'warning_red';
}

async function getSiteRoomsInfo(siteId) {
    const infoToGet = ['id', 'display_name']
    const rooms = await ajax.get(`${BASE_URL}/siteRoomsInfo`, null, { siteId, infoToGet })
    return rooms
}

function getDefaultRoom() {
    const room = {
        display_name: "",
        display_name_en: "",
        gender: "",
        building: "",
        floor: "",
        zone: "",
        site_id: "",
        cells_num: null,
        sinks_num: null,
        urinals_num: null,
        toilet_paper_size: null,
        is_calculated: true
    }
    return room
}

export default {
    generateRoomMap,
    getRooms,
    submitRoomList,
    removeRoomMany,
    submitRoom,
    getRoomById,
    removeRoom,
    getProcessedRooms,
    getDefaultRoom,
    getSiteRoomsInfo
}