<template>
  <md-tabs :md-is-rtl="isRTL" :md-alignment="alignment" :md-sync-route="syncRoute">
    <slot></slot>
  </md-tabs>
</template>

<script>
export default {
  props: {
    alignment: String,
    isRTL: { type: Boolean, default: false },
    syncRoute: { type: Boolean, default: false },
  }
};
</script>