<template>
  <md-tab :id="id" :md-label="label" :to="to" :exact="exact">
    <slot></slot>
  </md-tab>
</template>

<script>
export default {
  props: {
    id: String,
    label: {
      type: String,
      required: true
    },
    to: {
      type: String,
      required: false
    },
    exact: {
      type: Boolean,
      default: false
    }
  }
};
</script>