<template>
  <section class="map-view">
    <RoomModal
      v-if="isopenRoomModal"
      @closeModal="closeRoomModal"
      :roomProp="room"
      :surveys="surveys"
      @submit="submitRoom"
    />
    <DeviceModal
      v-if="isOpenDeviceModal && (room.id || selectedDevice.deviceCode)"
      @close="closeDeviceModal"
      :selectedRoom="room"
      @submit="submitDevice"
      :selectedDevice="selectedDevice"
    />
    <transition name="fade">
      <div class="custom-button-container">
        <span>{{ $t("search") }}:</span>
        <input type="text" v-model="searchByName" />
        <Button
          @click="removeSelectedRooms"
          v-if="isAdmin"
          :disabled="!selectedRooms.length"
          skin="accent"
          >{{ $t("delete") }}</Button
        >
        <Button @click="openRoomModal" v-if="isAdmin" skin="primary">{{
          $t("new_room")
        }}</Button>
      </div>
    </transition>

    <Tabs class="tabs-container" :isRTL="isRTL" syncRoute>
      <Tab :label="$t('rooms')" to="/admin/room/list">
        <section class="map-details">
          <Loading v-if="isLoading" class="loader" style="height: 75vh" />
          <Table
            v-else-if="roomsToShow.length && siteId"
            :tableData="roomsToShow"
            nestedIdField="id"
            @deleteNestedItem="removeSensor"
            :isNested="true"
            :isNestedEditable="false"
            :isSelect="isAdmin"
            idField="id"
            @select="handleSelectedItems"
            :editOptions="{ remove: isAdmin, edit: isAdmin }"
            :extraActions="[{ name: 'room-info', type: 'openRoomInfo' }, { name: 'qr_code', type: 'generateQR' }]"
            @action="handleActionClick"
            :isAdmin="isAdmin"
            @editRow="editRow"
            @removeRow="removeRow"
            :buttonText="isAdmin ? $t('add_device') : ''"
            @buttonClick="openDeviceModal"
            @nestedButtonClick="assignDevice"
            :nestedButtonText="isAdmin ? $t('assign_device') : ''"
            :initialOpenNested="initialOpenNested"
            @updatedNestedOpen="updateInitialOpenNested"
          />
          <div v-else>
            <h3>{{ $t("please select a company & site") }}</h3>
          </div>
        </section>
      </Tab>
      <Tab :label="$t('mapping')" to="/admin/room/generate">
        <MapGenerator v-if="siteId" @generated="updatePageData" />
        <div v-else>
          <h3>{{ $t("please select a company & site") }}</h3>
        </div>
      </Tab>
    </Tabs>
  </section>
</template>

<script>
import roomService from "../services/roomService";
import surveyService from '../../surveys/services/surveyService'
import swalService from "@/modules/common/services/swalService";
import MapGenerator from "./MapGenerator";
import RoomModal from "../components/RoomModal";
import DeviceModal from "../components/DeviceModal";
import sensorService from "../services/sensorService";
import Table from "@/modules/common/components/customTable/Table";
import Tabs from "@/modules/common/components/Tabs/Tabs";
import Tab from "@/modules/common/components/Tabs/Tab";
import Button from "@/modules/common/components/Button";
import Loading from "@/modules/common/components/Loading";
import { mapGetters } from 'vuex';
import QRCode from 'qrcode'

export default {
  data() {
    return {
      rooms: [],
      surveys: [],
      mapRoomSurveys: {},
      room: roomService.getDefaultRoom(),
      isLoading: false,
      selectedRooms: [],
      isopenRoomModal: false,
      isOpenDeviceModal: false,
      selectedDevice: {},
      initialOpenNested: [],
      searchByName: ''
    };
  },
  methods: {
    updateInitialOpenNested(openNestedTableIndexes) {
      this.initialOpenNested = openNestedTableIndexes;
    },
    handleActionClick({ type, idx }) {
      if (type === "openRoomInfo") this.openRoomInfo({ idx })
      else if (type === "generateQR") this.generateQR({ idx })
    },
    /**
     * @param {{
     * idx: number;
     * type: string;
     * }} param0
     * */
    openRoomInfo({ idx }) {
      if (!this.isAdmin) return
      const { id } = this.roomsToShow[idx];
      this.$router.push({ name: "roomDetails", params: { id } });
    },
    async generateQR({ idx, type = '' }) {
      const qrTypes = ['qr tag', 'qr task']
      if (type && !qrTypes.includes(type)) return swalService.errorMsg('Invalid QR code type')
      const domain = window.location.origin
      const room = this.roomsToShow[idx]
      const { ref_id, zone, gender, floor, building } = room
      let qrDomain = ''
      let deviceCode = ''
      if (type && qrTypes.includes(type)) {
        // Given a specific type, generate the QR code for that type
        const QR = room.nestedTable.find(({ type: qrType }) => qrType === type)
        if (!QR) return swalService.errorMsg('QR code is not found in this room')
        qrDomain = type === 'qr tag' ? 'survey-app' : 'task-app'
        deviceCode = QR.deviceCode
      } else if (!type) {
        // find which QR code to generate
        const qrs = []
        qrTypes.forEach(qrType => {
          const qr = room.nestedTable.find(({ type }) => type === qrType)
          if (qr) qrs.push(qr)
        })
        if (qrs.length === 0) return swalService.errorMsg('QR code is not found in this room')
        if (qrs.length === 1) {
          // only one qr code found, generate that qr code
          qrDomain = qrs[0].type === 'qr tag' ? 'survey-app' : 'task-app'
          deviceCode = qrs[0].deviceCode
        }
        if (qrs.length > 1) {
          // multiple qr codes found, print all qr codes
          qrs.forEach(qr => {
            this.generateQR({ idx, type: qr.type })
          })
          return
        }
      }
      const qrCodeImage = new Image()
      if (deviceCode.includes('QR:')) {
        deviceCode = deviceCode.replace('QR:', '')
      }
      const url = `${domain}/${qrDomain}/?QR:${deviceCode}`
      const text = `${ref_id || ''} ${zone || ''} ${gender || ''} ${floor ? `Floor ${floor}` : ''} ${building || ''}`
      const qrCodeImageData = await QRCode.toDataURL(url, { 
        width: 300,
        height: 300, 
        color: {
          dark: '#000000', // QR code color
          light: '#00000000' // Background color (fully transparent)
        }
      })
      qrCodeImage.onload = function () {
        const canvas = document.createElement('canvas')
        canvas.width = qrCodeImage.width
        canvas.height = qrCodeImage.height + 30
        const ctx = canvas.getContext('2d')
        ctx.drawImage(qrCodeImage, 0, 0)
        ctx.font = '12px Arial'; // Set the font for the text
        ctx.fillText(text, 75, qrCodeImage.height); // Draw the text below the QR code
        const a = document.createElement('a')
        a.download = `${room.display_name}-${qrDomain}-QR.png`
        a.href = canvas.toDataURL('image/png')
        a.click()
      }
      qrCodeImage.src = qrCodeImageData
    },
    async removeSelectedRooms() {
      const result = await swalService.confirmMsg();

      if (!result.value) return;

      try {
        const roomIds = this.selectedRooms.map(a => a.id);
        const res = await roomService.removeRoomMany(roomIds);
        swalService.deletedMsg();
        this.updatePageData();
      } catch (err) {
        swalService.errorMsg();
      }
    },
    closeDeviceModal() {
      this.closeRoomModal();
      this.isOpenDeviceModal = false;
      this.selectedDevice = {};
    },
    async removeSensor(deviceCode, roomId) {
      const result = await swalService.confirmMsg();
      if (!result.value) return;

      try {
        if (!deviceCode) throw new Error("no device code");
        else {
          await sensorService.remove(deviceCode);
          this.updatePageData();
          swalService.deletedMsg();
        }
      } catch (err) {
        swalService.errorMsg();
        console.warn(err.message);
      }
    },
    openDeviceModal(index) {
      this.isOpenDeviceModal = true;
      this.room = this.roomsToShow[index]
    },
    assignDevice(nestedIndex, roomIndex) {
      this.selectedDevice = this.roomsToShow[roomIndex].nestedTable[
        nestedIndex
      ];
      this.room = this.roomsToShow[roomIndex];
      this.isOpenDeviceModal = true;
    },
    closeRoomModal() {
      this.isopenRoomModal = false;
      this.room = roomService.getDefaultRoom();
    },
    openRoomModal() {
      this.isopenRoomModal = true;
    },
    async submitDevice(device) {
      try {
        if (this.selectedDevice.type) {
          await sensorService.updateSensor(device);
        } else {
          await sensorService.addSensor(device);
        }
        swalService.savedMsg(this.$t('Your work has been saved'))
        this.isOpenDeviceModal = false;
      } catch (err) {
        swalService.errorMsg();
      }
      this.updatePageData();
      this.selectedDevice = {};
    },
    async submitRoom(room) {
      delete room.device;
      try {
        if (!room.site_id) {
          this.addSiteIdToRoom(room)
        }
        await roomService.submitRoom(room);
        swalService.savedMsg(this.$t('Your work has been saved'))
        this.closeRoomModal();
      } catch (err) {
        swalService.errorMsg();
      }
      this.updatePageData();
    },
    editRow(rowData) {
      const { id } = rowData;
      const roomData = this.rooms.find(a => a.id === id);
      this.isopenRoomModal = true;
      this.room = { ...this.room, ...roomData };
      this.room.pad_survey_id = this.mapRoomSurveys[this.room.id + 'pad_survey_id']
      this.room.survey_id = this.mapRoomSurveys[this.room.id + 'survey_id']
      this.room.no_shift_survey_id = this.mapRoomSurveys[this.room.id + 'no_shift_survey_id']
      delete this.room.nestedTable;
    },
    async removeRow(roomId) {
      const result = await swalService.confirmMsg();

      if (!result.value) return;
      try {
        await roomService.removeRoom(roomId);
        swalService.deletedMsg();
      } catch (err) {
        swalService.errorMsg();
      }
      this.updatePageData();
    },
    async updatePageData() {
      this.mapRoomSurveys = {}
      this.isLoading = true;
      this.addSiteIdToRoom(this.room)
      const currFilter = this.$store.getters.filterSelected;
      const rooms = await roomService.getRooms(currFilter);
      if (!rooms) {
        await swalService.errorMsg()
        return
      }
      const roomsProcessed = roomService.getProcessedRooms(rooms, this.$t('last'));
      this.rooms = roomsProcessed;
      this.rooms = this.rooms.map(room => {
        this.mapRoomSurveys[room.id + 'pad_survey_id'] = room.pad_survey_id
        this.mapRoomSurveys[room.id + 'survey_id'] = room.survey_id
        this.mapRoomSurveys[room.id + 'no_shift_survey_id'] = room.no_shift_survey_id
        delete room.pad_survey_id
        delete room.survey_id
        delete room.no_shift_survey_id
        return room;
      })
      this.searchByName = ''
      this.surveys = await surveyService.getSurveyList({ site_id: this.siteId })
      this.isLoading = false;
    },
    handleSelectedItems(rooms) {
      this.selectedRooms = rooms;
    },
    addSiteIdToRoom(room) {
      room.site_id = this.siteId
    },
  },
  watch: {
    '$store.getters.filterSelected.site': {
      handler() {
        this.initialOpenNested = [];
        this.updatePageData();
      },
      immediate: true,
    }
  },
  computed: {
    ...mapGetters(['isRTL', 'loggedUser']),
    siteId() {
      return this.$store.getters.filterSelected.site;
    },
    isAdmin() {
      return this.loggedUser.type === 'admin'
    },
    roomsToShow() {
      const str = this.searchByName;
      const roomsData = this.rooms.map(room => {
        if (room) {
          const { cells_num, sinks_num, urinals_num, toilet_paper_size, display_name_en, ...roomToShow } = room
          return roomToShow
        }
      })
      if (!str) {
        return roomsData
      } else {
        return roomsData.filter(room => {
          return room.display_name.toLowerCase().includes(str.toLowerCase())
        }
        )
      }
    },
  },
  components: {
    Table,
    Button,
    Loading,
    Tabs,
    Tab,
    RoomModal,
    DeviceModal,
    MapGenerator
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/views/map.scss";
@import "@/styles/mixins.scss";

.map-view {
  position: relative;
}

.custom-button-container {
  height: 80px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  position: absolute;
  z-index: 3;
  right: 0;
  @include rtl {
    left: 0;
    right: unset;
  }
  span {
    @include modal-input-label;
    margin-bottom: 0px;
    font-size: 13px;
  }
  input {
    @include modal-input;
    margin-inline-start: 5px;
    margin-bottom: 0px;
    padding: 0 5px;
    width: 150px;
  }
  button {
    font-size: 12px;
    font-weight: bold;
  }
}

</style>