import ajax from '../../common/services/httpService'
const BASE_URL = 'sensor'

/**@typedef {{
 * site_id: Number;
 * room_id: Number;
 * deviceType: String;
 * company_id: Number;
 * desc: String;
 * device_code?: String;
 * }} Device*/

/**@typedef {{
 * company_id: number;
 * desc?:string;
 * device_code:string;
 * last_signal:string;
 * room_id:string | number;
 * sensor_code:string;
 * servikId?:number;
 * site_id:number | string;
 * type: string;
 * }} Sensor*/

async function getDeviceTypes() {
    const res = await ajax.get(`${BASE_URL}/types`)
    return res
}

/**@param {Device} device */
async function addSensor(device) {
    const res = await ajax.post(BASE_URL, device)
    return res
}

/**@param {Device} device */
async function updateSensor(device) {
    const res = await ajax.put(BASE_URL, device)
    return res
}

/**@param {String} device_code */
async function remove(device_code) {
    const res = await ajax.delete(BASE_URL, { device_code })
    return res
}

/**@param {number} roomId */
async function getSensorByRoomId(roomId) {
    /**@type {Sensor} */
    const sensor = await ajax.get(`${BASE_URL}/sensor/${roomId}`)
    return sensor
}


/**@param {string} deviceCode */
async function getByDeviceCode(deviceCode) {
    /**@type {Sensor} */
    const sensor = await ajax.get(`${BASE_URL}/${deviceCode}`)
    return sensor
}

async function getSitesSensorsStatus() {
    const tablet = await ajax.get(`${BASE_URL}/sitesDevices`)
    return tablet
}

async function getSensorErrors(deviceCode) {
    const errors = await ajax.get(`${BASE_URL}/errors/${deviceCode}`)
    return errors
}

async function getFaultyCounters() {
    const faultyCounters = await ajax.get(`${BASE_URL}/faultyCounters`)
    return faultyCounters
}

async function removeFaultyCounter(device_code) {
    const faultyCounter = await ajax.delete(`${BASE_URL}/faultyCounter`, { device_code })
    return faultyCounter
}

export default {
    addSensor,
    updateSensor,
    remove,
    getByDeviceCode,
    getDeviceTypes,
    getSensorByRoomId,
    getSitesSensorsStatus,
    getSensorErrors,
    getFaultyCounters,
    removeFaultyCounter
}