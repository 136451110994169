<template>
  <section class="map-generate-container">
    <form class="map-generate" v-if="!rooms.length">
      <h1>{{$t('hey, quickest way to add floors and rooms to a buildings via this quick wizard')}}</h1>
      <div class="map-generate-form">
        <div class="input-container">
          <div>{{$t('building')}} {{$t('name')}}</div>
          <input type="text" v-model="building.name" />
          <ErrorMsg :isShown="errors.includes('name')" :text="`${$t('please_enter')} ${$t('name')}`"/>
        </div>
        <div class="input-container">
          <div> {{$t('avg.number of toilets per floor')}}</div>
          <input type="number" v-model.number="building.roomNumber" min="0" max="200" />
          <ErrorMsg :isShown="errors.includes('roomNumber')" :text="`${$t('please_enter')} ${$t('number')}`"/>
        </div>
        <div class="input-container">
          <div> {{$t('avg.number of toilet cells per room')}}</div>
          <input type="number" v-model.number="building.avgCellNumber" />
        </div>
        <div class="input-container">
          <div> {{$t('number of floors')}}</div>
          <input type="number" v-model.number="building.floorNumber" min="0" max="100" />
          <ErrorMsg :isShown="errors.includes('floorNumber')" :text="`${$t('please_enter')} ${$t('number')}`"/>
        </div>
        <div class="input-container">
          <div> {{$t('avg.number of disabled toilets')}}</div>
          <input type="number" v-model.number="building.disabledToiletNumber" />
        </div>
        <div class="input-container">
          <div> {{$t('avg.number of soap dispensers')}}</div>
          <input type="number" v-model.number="building.soapDispensers" />
        </div>
      </div>
      <Button skin="primary" @click.prevent="createBuilding"> {{$t('create building map')}} </Button>
    </form>
    <section class="room-table" v-else>
      <Button skin="primary" @click="rooms = []"> {{$t('cancel')}} </Button>
      <Button skin="accent" @click="submitbuilding"> {{$t('confirm')}} </Button>
      <Table :tableData="rooms" :editOptions="{ edit: true, remove: true }" />
    </section>
  </section>
</template>

<script>
import Button from "@/modules/common/components/Button";
import Checkbox from '@/modules/common/components/Checkbox';
import ErrorMsg from '@/modules/common/components/ErrorMsg';
import Table from '@/modules/common/components/customTable/Table';
import roomService from "../services/roomService";
import swalService from "@/modules/common/services/swalService";

export default {
  data() {
    return {
      building: {
        name: "",
        roomNumber: "",
        avgCellNumber: "",
        floorNumber: "",
        disabledToiletNumber: "",
        soapDispensers: "",
        siteId: ""
      },
      rooms: [],
      isApproved: false,
      errors: [],
    };
  },
  created() {
    this.building.siteId = this.$store.getters.filterSelected.site;
  },
  methods: {
    checkForm() {
      const { name, roomNumber, floorNumber } = this.building;
      this.errors = [];
      if (!name) this.errors.push('name');
      if (!roomNumber) this.errors.push('roomNumber');
      if (!floorNumber) this.errors.push('floorNumber');
    },
    createBuilding() {
      this.checkForm();
      if (this.errors.length) return;
      this.rooms = roomService.generateRoomMap(this.building);
    },
    async submitbuilding() {
        try {
            await roomService.submitRoomList(this.rooms);
            this.$emit('generated');
            this.$router.push({ name: 'roomList' });
        } catch(err) {
            swalService.errorMsg();
        }
    }
  },
  components: { Button, Checkbox, Table, ErrorMsg }
};
</script>

<style lang="scss">
@import "@/styles/mixins.scss";

.map-generate-form {
  display: grid;
  grid-column-gap: 50px;
  grid-template-columns: 1fr 1fr;
}

.map-generate {
  @include flex-center;
  // text-transform: capitalize;
  flex-direction: column;
  h1 {
    font-size: $font-size-large;
    text-align: center;
    margin-bottom: 50px;
  }
  .input-container {
    input {
      @include modal-input;
    }

    div {
      @include modal-input-label;
    }
  }
}
</style>