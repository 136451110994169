<template>
  <Modal
    width="small"
    @close="emitCloseModal"
    :texts="{ headerPrimary: selectedDevice.type ? 'assign_device' : 'new_device' }"
    @submit="emitDevice"
  >
    <section class="form-container">
      <div class="input-container" v-if="selectedDevice.type">
        <div>{{$t('deviceCode')}}</div>
        <input type="text" class="modal-input" v-model="device.device_code" autofocus />
      </div>
      <div class="input-container">
        <div>{{$t('installSpot')}}</div>
        <input type="text" class="modal-input" v-model="device.desc" />
        <ErrorMsg
          :isShown="errors.includes('duplicate desc')"
          text="installation spot allready exists"
        />
        <ErrorMsg
          :isShown="errors.includes('missing installation spot')"
          :text="`${$t('please_enter')}`"
        />
      </div>
      <div class="input-container" v-if="site.name">
        <div>{{$t('site')}}</div>
        <Select v-model.number="device.site_id" class="select-container" disabled>
          <Option :label="site.name" :value="site.id" />
        </Select>
      </div>
      <div class="input-container">
        <div>{{$t('room')}}</div>
        <Select v-model.number="device.room_id" class="select-container" disabled>
          <Option :label="selectedRoom.display_name" :value="selectedRoom.id" />
        </Select>
      </div>
      <div class="input-container">
        <div>{{$t('device_type')}}</div>
        <Select v-model="device.deviceType" class="select-container">
          <Option v-for="type in deviceTypes" :key="type" :label="$t(type.toLowerCase())" :value="type" />
        </Select>
        <ErrorMsg :isShown="errors.includes('missing type')" :text="`${$t('please_enter')}`" />
      </div>
    </section>
  </Modal>
</template>

<script>
import Modal from '../../common/components/Modal';
import Select from '../../common/components/Select';
import Option from '../../common/components/Option';
import ErrorMsg from '../../common/components/ErrorMsg';
import siteService from '../services/siteService';
import sensorService from '../services/sensorService';

export default {
  props: {
    selectedRoom: { type: Object, required: true },
    selectedDevice: { type: Object, required: true }
  },
  data() {
    return {
      site: {},
      device: { site_id: '', room_id: '', deviceType: '', company_id: '', desc: '', device_code: '' },
      deviceTypes: ['counter', 'sensor liquid', 'toilet paper', 'double toilet paper', 'counter woman', 'counter man/woman', 'counter handicap'],
      roomDescriptions: [],
      errors: [],
     currentDevice:null
    }
  },
  async created() {
    this.site = await siteService.getSiteById(this.$store.getters.filterSelected.site);
    this.device.room_id = this.selectedRoom.id;
    this.device.company_id = this.$store.getters.filterSelected.company;
    this.device.site_id = this.$store.getters.filterSelected.site;
    this.deviceTypes = await sensorService.getDeviceTypes()
    if (this.selectedDevice.type) {
      if (!this.deviceTypes.includes(this.selectedDevice.type)) this.deviceTypes.push(this.selectedDevice.type);
      this.device.deviceType = this.selectedDevice.type;
      this.device.desc = this.selectedDevice.installSpot;
      this.device.device_code = this.selectedDevice.deviceCode;
      this.currentDevice=this.selectedDevice.deviceCode;
    } else {
      this.roomDescriptions = this.selectedRoom.nestedTable.reduce((acc, sensor) => {
        if (sensor.installSpot) acc.push(sensor.installSpot);
        return acc;
      }, []);
    }
  },
  methods: {
    emitDevice() {
      this.checkForm();
      if (this.errors.length) return;
      if(this.currentDevice&&this.currentDevice!==this.device.device_code)this.device.oldDevice=this.currentDevice;
      this.$emit('submit', this.device);
    },
    checkForm() {
      const { deviceType, desc } = this.device;
      this.errors = [];
      if (this.roomDescriptions.includes(this.device.desc)) this.errors.push('duplicate desc')
      if (!deviceType) this.errors.push('missing type');
      // if (!desc) this.errors.push('missing installation spot');
    },
    emitCloseModal() {
      this.$emit('close');
    }
  },
  components: { Modal, Select, Option, ErrorMsg }
}
</script>

<style lang="scss" scoped>
@import "@/styles/vars";
@import "@/styles/mixins";

.md-field.select-container {
  width: 17vw;
}

.error-msg {
  @include error-msg;
}

.select-container {
  border-bottom: 1px solid $grey-border-color;
}

.input-container {
  margin-bottom: 15px;
  input {
    @include modal-input;
    padding: 0 10px;
  }

  div {
    @include modal-input-label;
    margin-bottom: 0;
  }
}
</style>